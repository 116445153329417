var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"row margin-0"},[_c('div',{staticClass:"col-12 pad-0"},[_vm._m(0),_c('div',{staticClass:"contact-body"},[_c('div',{staticClass:"row margin-0"},[_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col"},[_c('h3',{staticClass:"margin-20 text-center"},[_vm._v("Explore Our Products")]),_c('br'),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('br'),_c('h3',{staticClass:"margin-top-20 text-center"},[_vm._v("Why Choose Our Products")]),_c('div',{staticClass:"row margin-0"},[_c('div',{staticClass:"col-6 pad-right-0"}),_vm._m(5),_c('div',{staticClass:"col-12 pad-20 text-center"},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("Partner with Us -->")])],1)])]),_c('div',{staticClass:"col-1"})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-header"},[_c('div',{staticClass:"row margin-0"},[_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('h1',[_vm._v("INNOVATIVE PRODUCTS THAT DEFINE THE FUTURE")]),_c('p',[_vm._v("Innovation is not just a concept; it's our foundation. Our Products page is a testament to our commitment to pushing the boundaries of what's possible. Dive into a world where technology meets creativity, and explore a suite of cutting-edge products designed to redefine industries and elevate user experiences.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row margin-0 product-tile"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("EBechana")]),_c('p',[_vm._v("EBechana is a online free classified platform to connect a customer to customer based on their requirements. It also helps businesses to create their own store for free. Customer or a Business user can create unlimited ads. Their will be no fee for ads.")]),_c('a',{attrs:{"href":"http://ebechana.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("visit ebechana.com -->")])]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-5"},[_c('img',{staticClass:"product-img",attrs:{"src":"/ebechana.png","alt":"","srcset":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row margin-0 product-tile"},[_c('div',{staticClass:"col-5"},[_c('img',{staticClass:"product-img",attrs:{"src":"/newhyre.png","alt":"","srcset":""}})]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-6"},[_c('h4',[_vm._v("NEWHYRE")]),_c('p',[_vm._v("NewHyre, represents the culmination of our dedication to creating exceptional solutions. Designed with a focus on problem-solving and productivity enhancement, it's the platform where careers are forged and dreams are realized.")]),_c('a',{attrs:{"href":"http://newhyre.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("visit newhyre.com -->")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row margin-0 product-tile"},[_c('div',{staticClass:"col-6"},[_c('h4',[_vm._v("HIRRER")]),_c('p',[_vm._v("Hirrer Automated hiring software is smarter, simpler easy to adapt to your work environment. Interestingly our smart features never allow you to search for any other ATS. No exaggeration, tap on your free trial you would say wow!")]),_c('a',{attrs:{"href":"http://hirrer.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("visit hirrer.com -->")])]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-5"},[_c('img',{staticClass:"product-img",attrs:{"src":"/hirrer.png","alt":"","srcset":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row margin-0 product-tile"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("ONE EMR "),_c('sup',{staticStyle:{"color":"orange","font-size":"10px"}},[_vm._v("Coming Soon")])]),_c('p',[_vm._v("ONE EMR is your comprehensive solution for seamless healthcare management. ONE EMR is not just an application; it's a revolutionary platform that brings all-encompassing features to hospitals and clinics. With one click, ONE EMR transforms the way medical histories are stored and accessed. Experience a new era of healthcare connectivity, where efficiency meets innovation, and your medical journey is simplified with just ONE EMR.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col pad-left-0"},[_c('h4',[_vm._v("Innovation Beyond Imagination:")]),_c('p',[_vm._v("Our products are crafted at the intersection of innovation and imagination, pushing the boundaries of what technology can achieve.")]),_c('h4',[_vm._v("User-Centric Design:")]),_c('p',[_vm._v("With a focus on user experience, our products are designed to be intuitive, user-friendly, and seamlessly integrate into various workflows.")]),_c('h4',[_vm._v("Industry-Leading Features:")]),_c('p',[_vm._v("Stay ahead of the curve with industry-leading features that not only meet but exceed the evolving needs of your business.")]),_c('h4',[_vm._v("Reliability and Scalability:")]),_c('p',[_vm._v("Built on a foundation of reliability and scalability, our products are equipped to grow with your business, ensuring long-term success.")])])
}]

export { render, staticRenderFns }